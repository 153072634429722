export default {
    data() {
        return {
            //rootUrl : 'http://www.noc19.kr',

            // 상용 서버
            // rootUrl : 'https://noc19-api.sidrec.go.kr',

            // 개발 서버
            rootUrl : 'localhost:8080',

            userDataAdminId:(this.storageGet('userInfo')) ? this.storageGet('userInfo', 'adminId') : '',                    // 로그인 아이디
            userDataAdminName:(this.storageGet('userInfo')) ? this.storageGet('userInfo', 'adminName') : '',                // 로그인 이름
            userDataAdminPermission:(this.storageGet('userInfo')) ? this.storageGet('userInfo', 'adminPermission') : '',    // 로그인 권한
            userDataAdminType:(this.storageGet('userInfo')) ? this.storageGet('userInfo', 'adminType') : '',                // 로그인 타입(ADMIN, MANAGER, MOBILE)

            searchCondition:[{code:'name',name:'이름'}], // 검색 키워드
            searchConditionFList:[{code:'assigned',name:'배정일'},{code:'register',name:'접수일'}], // 검색 키워드
            searchConditionConfirmedList:[{code:'name',name:'이름'},{code:'birth',name:'생년월일'},{code:'fasility',name:'시설명'},{code:'nation',name:'국적'},{code:'memo',name:'메모'}], // 검색 키워드
        }
    },
    // 계산된 속성
    computed: {
    },
    // 감시자
    watch: {
    },
    // $el 생성전
    created() {
    },
    // $el 생성후
    mounted() {
        window.callbackBackKey = this.callbackBackKey;
    },
    methods: {
        // 뒤로가기 키 클릭
        callbackBackKey(){
            window.Android.exitApp();
        },
        showAlert(title, message, callBack = undefined, param = undefined){
            this.$swal({
                title: title,
                text: message,
                html:message,
                confirmButtonText: "닫기"
            }).then((result) => {
                if (callBack && result.value){
                    this[callBack](param !== undefined ? param : result);
                }
            });
        },
        showConfirm(title, message, callBack = undefined, param = undefined){
            this.$swal({
                title: title,
                text: message,
                html:message,
                showCancelButton: true,
                confirmButtonColor : '#3695ff',
                confirmButtonText: '확인',
                cancelButtonText: '취소',
                customClass: 'custom-swal-alert ',
                closeOnConfirm: false,
                closeOnCancel: true,
                closeModal: false,
                showLoaderOnConfirm : callBack !== undefined ? true : false
            }).then((result) => {
                if (callBack && result.value){
                    this[callBack](param !== undefined ? param : result);
                }
            });
        },
        ajaxGet(url, params, callBack){
            this.$store.commit('loading', true);
            axios.get(url,{ params: params})
                .then((response)  =>  {
                    this.$store.commit('loading', false);
                    if (response.data.code === 200){
                        console.log("콜백");
                        if (callBack){
                            this[callBack](response.data,params);
                        }
                    }else{
                        console.log("200아님");
                        this.showAlert('', response.data.message);
                        return false;
                    }
                }, (error)  =>  {
                    console.log("에러");
                    this.$store.commit('loading', false);
                    if (error) {
                        this.showAlert('', error.response.data.message);
                    }
                })
        },
        ajaxPost(url, formData, callBack){
            this.$store.commit('loading', true);
            axios.post(url,formData,{headers:{'Content-Type': 'application/json'}})
                .then((response)  =>  {
                    this.$store.commit('loading', false);
                    if (response.data.code == 200){
                        if (callBack){
                            this[callBack](response.data,formData);
                        }
                    }else{
                        this.showAlert('', response.data.message);
                        return false;
                    }

                }, (error)  =>  {
                    this.$store.commit('loading', false);
                    if (error) this.showAlert('', error.response.data.message);
                })
        },
        storageGet(item, key){
            let _data = JSON.parse(sessionStorage.getItem(item));
            if (key !== undefined){
                return _data[key];
            }else{
                return _data;
            }
        },
        storageSet(item, data){
            sessionStorage.setItem(item, JSON.stringify(data))
        },
        storageRemove(item){
            sessionStorage.removeItem(item);
        },
        storageClear(){
            sessionStorage.clear();
        },
        localStorageGet(item, key){
            let _data = JSON.parse(localStorage.getItem(item));
            if (key !== undefined){
                return _data[key];
            }else{
                return _data;
            }
        },
        localStorageSet(item, data){
            localStorage.setItem(item, JSON.stringify(data))
        },
        localStorageRemove(item){
            localStorage.removeItem(item);
        },
        localStorageClear(){
            localStorage.clear();
        },
        logOutFunc(){
            this.storageClear();
            this.localStorageRemove('userId');
            if (this.userDataAdminType == 'ADMIN'){
                this.$router.push('/admin/login');
            }else if (this.userDataAdminType == 'MANAGER'){
                this.$router.push('/manager/login');
            }else{
                this.$router.push('/mobile/login');
            }
        },
        menuLink(name){
            if (this.$router.currentRoute.name != name){
                this.$router.push({ name: name})
            }
        },
        checkLogin(){
            let urlCheck = [];
            if (this.userDataAdminType == 'ADMIN'){
                urlCheck.push('AdminLogin');
                urlCheck.push('AdminDashboardIndex');
                urlCheck.push('AdminConfirmedList');
                urlCheck.push('AdminConfirmedDetail');
                urlCheck.push('AdminFacilityList');
                urlCheck.push('AdminStatisticsFacility');
                urlCheck.push('AdminStatisticsSeverity');
                urlCheck.push('AdminStatisticsArea');
                urlCheck.push('AdminStatisticsDetail');
                urlCheck.push('AdminStatisticsUnassign');
                urlCheck.push('AdminStatisticsDateassign');
                urlCheck.push('AdminStatisticsSelfcare');
                if (this.userDataAdminPermission === 'G1106'){
                    urlCheck.push('AdminAdminList');
                    urlCheck.push('AdminAdminDetail');
                    urlCheck.push('AdminFacilityDetail');
                }
            }else if (this.userDataAdminType == 'MANAGER'){
                urlCheck.push('AdminLogin');
                urlCheck.push('ConfirmedListManager');
                urlCheck.push('ConfirmedListManagerSearch');
            }else{
                urlCheck.push('MobileLogin');
                urlCheck.push('ConfirmedListMobile');
            }

            if (!urlCheck.includes(this.$route.name)){
                this.logOutFunc()
            }
        },
        excelExport(url){
            location.href = url;
        },
        // 정보 복사
        copyInfo(obj){
            let birth = (obj.f_patient_birth != '0000-00-00 00:00:00') ? this.$moment(obj.f_patient_birth).format('YYYY-MM-DD') : '';
            let mag = '<'+obj.f_region+'> '+ obj.f_patient_name +' '+ birth +' '+obj.f_patient_sex;
            let textarea = document.createElement('textarea');
            textarea.value = mag;

            document.body.appendChild(textarea);
            textarea.select();
            textarea.setSelectionRange(0, 9999);  // 추가

            document.execCommand('copy');
            document.body.removeChild(textarea);

            this.showAlert('', '[ '+mag+' ] 정보가 복사되었습니다.');
        },
    }
}
