import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: {
      name: "AdminLogin"
    }
  },
  { path: '*', redirect: '/' },
  {
    path: '/admin/login',
    name: 'AdminLogin',
    component: () => import('../views/AdminLogin.vue'),
    props: true
  },
  {
    path: '/admin/dashboard',
    name: 'AdminDashboardIndex',
    component: () => import('../views/dashboard/Index.vue'),
    props: true
  },
  {
    path: '/admin/confirmedList',
    name: 'AdminConfirmedList',
    component: () => import('../views/confirmed/ConfirmedList.vue'),
    props: true
  },
  {
    path: '/admin/confirmedDetail',
    name: 'AdminConfirmedDetail',
    component: () => import('../views/confirmed/ConfirmedDetail.vue'),
    props: true
  },
  {
    path: '/admin/facilityList',
    name: 'AdminFacilityList',
    component: () => import('../views/facility/FacilityList.vue'),
    props: true
  },
  {
    path: '/admin/facilityDetail',
    name: 'AdminFacilityDetail',
    component: () => import('../views/facility/FacilityDetail.vue'),
    props: true
  },
  {
    path: '/admin/statisticsFacility',
    name: 'AdminStatisticsFacility',
    component: () => import('../views/statistics/facility.vue'),
    props: true
  },
  {
    path: '/admin/statisticsSeverity',
    name: 'AdminStatisticsSeverity',
    component: () => import('../views/statistics/severity.vue'),
    props: true
  },
  {
    path: '/admin/statisticsArea',
    name: 'AdminStatisticsArea',
    component: () => import('../views/statistics/area.vue'),
    props: true
  },
  {
    path: '/admin/statisticsUnassign',
    name: 'AdminStatisticsUnassign',
    component: () => import('../views/statistics/unassign.vue'),
    props: true
  },
  {
    path: '/admin/statisticsDateassign',
    name: 'AdminStatisticsDateassign',
    component: () => import('../views/statistics/dateassign.vue'),
    props: true
  },
  {
    path: '/admin/statisticsSelfcare',
    name: 'AdminStatisticsSelfcare',
    component: () => import('../views/statistics/safecare.vue'),
    props: true
  },
  {
    path: '/admin/statisticsDetail',
    name: 'AdminStatisticsDetail',
    component: () => import('../views/statistics/statisticsDetail.vue'),
    props: true
  },

  {
    path: '/admin/adminList',
    name: 'AdminAdminList',
    component: () => import('../views/admin/AdminList.vue'),
    props: true
  },
  {
    path: '/admin/adminDetail',
    name: 'AdminAdminDetail',
    component: () => import('../views/admin/AdminDetail.vue'),
    props: true
  },




  {
    path: '/manager/login',
    name: 'ManagerLogin',
    redirect: {
      name: "AdminLogin"
    },
  },
  {
    path: '/manager/confirmedList',
    name: 'ConfirmedListManager',
    component: () => import('../views/confirmed/ConfirmedListManager.vue'),
    props: true
  },
  {
    path: '/manager/confirmedListSeaerch',
    name: 'ConfirmedListManagerSearch',
    component: () => import('../views/search/ConfirmedListManagerSearch.vue'),
    props: true
  },

  {
    path: '/mobile/login',
    name: 'MobileLogin',
    component: () => import('../views/MobileLogin.vue'),
    props: true
  },
  {
    path: '/mobile/confirmedList',
    name: 'ConfirmedListMobile',
    component: () => import('../views/confirmed/ConfirmedListMobile.vue'),
    props: true
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
