import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

// @ts-ignore
import VueSlideoutPanel from "vue2-slideout-panel";
Vue.use(VueSlideoutPanel);

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
Vue.use(VueSweetalert2);

// @ts-ignore
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'
Vue.use(VueAwesomeSwiper, /* { default global options } */)

// @ts-ignore
import infiniteScroll from 'vue-infinite-scroll'
Vue.use(infiniteScroll)

// @ts-ignore

import VueScroller from 'vue-scroller'
Vue.use(VueScroller)

import InfiniteLoading from 'vue-infinite-loading';
Vue.use(InfiniteLoading);

// @ts-ignore
import mixins from '@/plugins/mixin';
Vue.mixin(mixins);

// @ts-ignore
import filters from '@/plugins/filters';
Vue.mixin(filters);

// @ts-ignore
import VueCryptojs from 'vue-cryptojs'
Vue.use(VueCryptojs)

// @ts-ignore
import VueMoment from 'vue-moment';
Vue.use(VueMoment)

// @ts-ignore
import VueTheMask from 'vue-the-mask'
Vue.use(VueTheMask)

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
