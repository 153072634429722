<template>
    <div id="app">
        <div class="wrap">
            <router-view/>
        </div>
        <div class="loading" v-if="isLoading" delay="500">
            <p>로딩중</p>
        </div>
  </div>
</template>

<script>
    import { mapState } from 'vuex';

    export default {
        name: 'app',
        data() {
            return {
                userDataAdminId:(this.storageGet('userInfo')) ? this.storageGet('userInfo', 'adminId') : ''
            }
        },
        // 계산된 속성
        computed: {
            ...mapState(['isLoading', 'refCount'])
        },
        // 감시자
        watch: {
        },
        // $el 생성전
        created() {
        },
        // $el 생성후
        mounted() {
        },
        methods: {
        }
    }
</script>

<style lang="scss">
    @import './assets/css/default.css';
    @import './assets/css/reset.css';
    @import './assets/css/style.css';
</style>
