var numeral = require("numeral");

export default {
    filters: {
        comma:function (value){
            return numeral(value).format("0,0");
        },
        arrayIndexof:function (arr,value){
            let txt = '';
            if (arr.length > 0){
                arr.forEach(function (item) {
                    if(item.idx == value){
                        txt = 'select';
                    }
                });
            }
            return txt;
        },
        arrayIndexofNum:function (arr,value){
            let txt = '';
            if (arr.length > 0){
                arr.forEach(function (item,index) {
                    if(item.idx == value){
                        txt = index+1;
                    }
                });
            }
            return txt;
        },
        arrayIndexofName:function (arr,value){
            let txt = '';
            if (arr.length > 0){
                arr.forEach(function (item,index) {
                    if(item.code == value){
                        txt = item.name;
                    }
                });
            }
            return txt;
        },
        dateDiff:function(startDateTime, endDateTime){
            let startDate = new Date(startDateTime);
            let endDate = new Date(endDateTime);

            // 두 일자(startTime, endTime) 사이의 차이를 구한다.
            let dateGap = endDate.getTime() - startDate.getTime();
            let timeGap = new Date(0, 0, 0, 0, 0, 0, endDate - startDate);

            // 두 일자(startTime, endTime) 사이의 간격을 "일-시간-분"으로 표시한다.
            let diffDay  = Math.floor(dateGap / (1000 * 60 * 60 * 24)); // 일수
            let diffHour = timeGap.getHours();       // 시간
            let diffMin  = timeGap.getMinutes();      // 분
            let diffSec  = timeGap.getSeconds();      // 초

            let isolateDateTime='';
            if (diffDay > 0) isolateDateTime += diffDay + "일 ";
            if (diffHour > 0) isolateDateTime += diffHour + "시간";

            if (isolateDateTime == '') isolateDateTime='-';

            return isolateDateTime;
        }
    }
}
